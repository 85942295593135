@import 'mixins';
@import 'variables';

html {
  transition: 1s;
  scroll-behavior: smooth;
}

.container-1 {
  width: 100%;
  min-height: 40px;
  background: rgb(195, 34, 164);
  background: linear-gradient(98deg, rgba(195, 34, 164, 1) 0%, rgba(68, 14, 39, 1) 43%, rgba(142, 22, 95, 1) 100%);
}

.container-2 {
  background-color: rgb(255, 255, 255);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.transparent {
  background-color: transparent;
}

.blured-bg {
  backdrop-filter: blur(5px),
}

.primary-fore-color {
  color: $primary-color;
}


.heading {
  background-color: rgb(198, 198, 198);
  color: $primary-color;
  font-weight: bold;
  padding: 5px;
}

.heading-2 {
  background-color: rgb(236, 236, 236);
  color: $primary-color;
  font-weight: bold;
  padding: 5px;
}

.theme-bg-primary {
  background-color: $primary-color;
  color: #fff;
}

.app-back-color {
  background-color: #fff;
}

.marquee-image-height {
  height: 70vh;
}

.floating-container {  
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  // animation-timing-function: ease-in-out;
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 5px); }
  to   { transform: translate(0, -0px); }    
}

//   External imports
@import "spinner";